.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #302834;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
}

.App-section {
  height: 90vh;
  display: flex;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-section canvas {
  top: 10vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: fixed;
}

.App-link {
  color: #61dafb;
}

.App-header select {
  font-size: 2rem;
  padding: 0.2rem 0.5rem;
  text-align: center;
  border-radius: 0.5rem
}