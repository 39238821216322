.problem-container {
  font-size: 2.5rem;
  flex-direction: column;
  border: 2px solid #222;
  border-radius: .5rem;
  padding: 1rem;
}

.problem-container.error {
  border-color: #d00;
}

.problem {
  font-family: 'Familiar Bold';
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  background-color: #1a1c1d;
}
.solutions {
  display: flex;
  flex-direction: row;
  width: 70vw;
  justify-content: space-evenly;
}
.solution {
  display: flex;
}

.correct {
  margin-top: 3rem;
  font-size: 1.5rem;
  border: 2px solid #222;
  border-radius: 0.5rem;
  padding: 1rem;
}

.correct > button {
  margin-top: 1rem;
}

.correct > button,
.solution > button {
  font-size: 2rem;
}

.problem-container.noun .display-word:before {
  content: "\00a0";
}